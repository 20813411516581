<template>
<div style="width:100%">
  <v-row no-gutters>
    <v-col cols="12" md="6"><b>OTRO</b></v-col>
    <v-col cols="12" md="5">
      <v-icon size="18" :color="(item.correoenvio_id === 1)?'green':'red'">mdi-email</v-icon> {{ item.correo }}
    </v-col>
    <v-col cols="12" md="1">
      <v-icon v-if="item.correoenvio_id === 1" size="18" color="green">mdi-check-bold</v-icon>
      <v-icon v-else size="18" color="red">mdi-close-octagon</v-icon>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12" v-for="(itemFile, index) in item.gesotrofiles" :key="index">
      <v-icon size="15">mdi-paperclip</v-icon> {{ itemFile.original }}
      <v-btn @click="download(itemFile)" icon><v-icon color="grey lighten-1">mdi-cloud-download</v-icon></v-btn>
    </v-col>
  </v-row>
  <v-container fluid v-if="item.gesotrorespuestas.length">
    <v-row no-gutters v-for="(itemRes, index) in item.gesotrorespuestas" :key="index">
      <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="12" md="1" align-self="center">
              <v-icon v-if="validarFecha(itemRes)" :color="colores.green" class="parpadea">mdi-email</v-icon>
              <v-icon v-else>mdi-email</v-icon>
            </v-col>
            <v-col cols="12" md="5">
              <v-row no-gutters>
                <v-col> {{ itemRes.fecha }}</v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>{{ itemRes.de }}</v-col>
              </v-row>
              </v-col>
            <v-col cols="12" md="5"><v-list-item-title  style="font-size:9px">{{ itemRes.asunto }}</v-list-item-title></v-col>
            <v-col cols="12" md="1">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn @click="abrirCorreo(itemRes)" icon><v-icon>mdi-eye</v-icon></v-btn>
                </v-col>
              </v-row>
              </v-col>
          </v-row>
          <v-row no-gutters  v-for="(itemFile, i) in itemRes.gesotrorespuestafiles" :key="i">
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="11">
              <v-row no-gutters>
                <v-col cols="12" md="1"><v-icon size="20">mdi-paperclip</v-icon></v-col>
                <v-col cols="12" md="11">{{ itemFile.archivo }}</v-col>
              </v-row>
              </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
    </v-row>
  </v-container>
  <dialgoVerCorreo :dialogo="dialogoCorreo" :colores="colores" :id_proyecto="id_proyecto" :id_correo="id_correo" :tokenCorreo="tokenCorreo" @closed_correo="cerrarVentaCorreo"></dialgoVerCorreo>
</div>

</template>

<script>
// import dialgoVerCorreo from '@/components/correos/dialogo_verComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
import recUpload from '@/js/rec_upload.js'
export default {
  name: 'lectura_logs_escalamientosComponent',
  components: {
    dialgoVerCorreo: () => import('@/components/correos/dialogo_verComponent.vue')
  },
  mixins: [recFunciones, recUpload],
  props: ['colores', 'item'],
  data: () => ({
    dialogoCorreo: false,
    tokenCorreo: 0,
    id_proyecto: 0,
    id_correo: 0
  }),
  created () {

  },
  methods: {
    abrirNotificacion () {
      this.dialogoMail = true
    },
    cerrarNotificacion () {
      this.dialogoMail = false
    },
    abrirCorreo (pItem) {
      this.id_proyecto = pItem.id_proyecto
      this.id_correo = pItem.id_correo
      this.tokenCorreo = this.tokenCorreo + 1
      this.dialogoCorreo = true
    },
    cerrarVentaCorreo () {
      this.dialogoCorreo = false
    },
    validarFecha (itemRes) {
      if (itemRes !== null) {
        const nowDate = this.rec_funciones_fecha_actual()
        const mailDate = this.rec_funciones_fecha(new Date(itemRes.fecha))
        if (nowDate === mailDate) {
          return true
        } else {
          return false
        }
      }
    },
    download (data) {
      this.rec_upload_escalamiento_otro_download(data.id, data.original)
    }

  }

}
</script>
<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
